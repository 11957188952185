$(function () {
    var btn = $('.js_open_menu'),
        menu = $('.js_menu'),
        blackout = $('.blackout'),
        close_btn = $('.js_close_menu');

    btn.click(function () {
        menu.addClass('is-active'); 
        blackout.addClass('is-active');
    });

    blackout.click(function () {
        menu.removeClass('is-active');
        blackout.removeClass('is-active');
    });

    close_btn.click(function () {
        menu.removeClass('is-active');
        blackout.removeClass('is-active'); 
    });
});

$('#feedback_form').submit(function (e) {
    e.preventDefault();
    $.ajax({ 
        url: "/form.php",
        type: "POST",
        data: $(this).serialize(),
        success: function (response) { 
            $('.feedback-block__input').val('');
            $('.feedback-block__form-text').addClass('is-active');
            setTimeout(function() {
                $('.feedback-block__form-text').removeClass('is-active'); 
            }, 4000);
        },
        error: function (response) {
        } 
    });
});

$('.js_title_slider').slick({
    slidesToShow: 1, 
    slidesToScroll: 1,
    speed: 300,
    arrows: false,
    adaptiveHeight: true,
    infinite: true,
    dotsClass: 'slick-dots slick-dots--yellow',
    dots: true
});

$('.js_clients_slider').slick({ 
    slidesToShow: 3, 
    slidesToScroll: 1,
    speed: 300,
    arrows: false,
    infinite: false,
    dots: true, 
    responsive: [{
            breakpoint: 850,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 425,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },

    ]
});

$(function(){
    var block = $('.js_question'),
        text = $('.questions-block__item-desc');
 
    block.click(function () {
        if ($(this).hasClass('is-active')) {
            $(this).animate({
                'max-height': parseInt($(this).find('.questions-block__item-title').css("height"))
            }, 0);
            $(this).removeClass('is-active') 
        }else{ 
            $(this).addClass('is-active')
            $(this).animate({
                'max-height': parseInt($(this).find('.questions-block__item-title').css("height")) + parseInt($(this).find(text).css("height"))
            }, 0);  
        } 
    })

    $(window).resize(function(){  
        for (i = 0; i < block.length; i++){
            block.eq(i).animate({
                'max-height': parseInt(block.eq(i).find('.questions-block__item-title').css("height"))
            }, 0);
        }
    })

    if ($(window).width()<=767){
        $('.js_partners_slider').slick({
            slidesToShow: 5,
            slidesToScroll: 1, 
            autoplay: true,
            speed: 300,
            arrows: false,
            infinite: true,
            dots: true,
            responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                }, {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },

            ]
        });
        $('.js_types_slider').slick({  
            slidesToShow: 5,
            slidesToScroll: 1,
            speed: 300,
            variableWidth: true,
            arrows: true,
            infinite: false,
            dots: false,

            responsive: [{
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },

            ]
        });
    }

    $(document).ready(function () {
        for (i = 0; i < block.length; i++) {
            block.eq(i).animate({
                'max-height': parseInt(block.eq(i).find('.questions-block__item-title').css("height")) 
            }, 0);

            if (block.eq(i).hasClass('is-active')){ 
                block.eq(i).animate({
                    'max-height': parseInt($(this).find('.questions-block__item-title').css("height")) + parseInt(text.css("height"))
                }, 0); 
            }
        }
    });

    /* block.each(function () {
        if ($(this).hasClass('is-active')) {
            

        }
        $(this).removeClass('is-active'); 
    }); */
})
 
$(function(){
    $('.input--phone').click(function () {
        $(this).setCursorPosition(4);
    }).mask("+ 7(999) 999-99-99");  
});

$(document).ready(function(){
    var btn = $('.equipment__desc-nav'),
        block = $('.equipment__desc');  

    for (i = 0; i < block.length; i++) {
        if (block.eq(i).height() >= 67 && !(block.eq(i).hasClass('no-active'))) {
            block.eq(i).addClass('is-big');
            block.eq(i).parent().find(btn).addClass('is-active');
        }
    }
}) 

$.fn.setCursorPosition = function (pos) {
    if ($(this).get(0).setSelectionRange) {
        $(this).get(0).setSelectionRange(pos, pos);
    } else if ($(this).get(0).createTextRange) {
        var range = $(this).get(0).createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
};

$(function(){
    var rubricator_b = $('.js_products_rubricator'),
        rubric = $('.js_product_rubric');  

    rubricator_b.click(function(e){ 
        rubricator_b.removeClass('is-active');
        $(this).addClass('is-active'); 
        e.preventDefault();
    });

    for (let i = 0; i < rubricator_b.length; i++) {
        rubricator_b.eq(i).click(function () {
            rubric.removeClass('is-active');  
            rubric.eq(i).addClass('is-active');
            rubric.eq(i).find('.js_products_slider').slick('refresh'); 
        });
    }
})

$(function () {
    $('.js_video_wrap').click(function () {
        $(this).addClass('play');
        $(this).find('.js_video').attr('src', $(this).find('.js_video').attr('data-src'));
    });

    $('.slick-dots').each(function () {
        if ($(this).find('li').length < 2 && !$(this).hasClass('hidden')) {
            $(this).addClass('hidden')
        }
    })
})

$(function () {
    var block = $('.js_toggle'),
        text = $('.js_text');

    block.click(function (e) {
        e.preventDefault();
        if ($(this).hasClass('is-active')) {
            $(this).parent().animate({
                'max-height': parseInt(block.eq(2).parent().css("height"))
            }, 0);
            $(this).removeClass('is-active')
        } else {
            $(this).addClass('is-active')
            $(this).parent().animate({
                'max-height': 1000
            }, 0); 
        }
    })

    $(document).ready(function () {
        for (i = 0; i < block.length; i++) {   
            if (block.eq(i).parent().height() > block.eq(2).parent().height()) {
                block.eq(i).addClass("visible");
            }
            block.eq(i).parent().animate({ 
                'max-height': parseInt(block.eq(2).parent().css("height"))
            }, 0);
        }
    })

    $(document).ready(function () {
        for (i = 0; i < block.length; i++) { 
            block.eq(i).parent().animate({ 
                'max-height': parseInt(block.eq(2).parent().css("height"))
            }, 0); 

            if (block.eq(i).hasClass('is-active')) {
                block.eq(i).parent().animate({
                    'max-height': parseInt($(this).parent().css("height")) + parseInt(text.css("height"))
                }, 0);
            }

            if ($(window).width() <= 550 && block.eq(i).hasClass('is-active')) {
                block.eq(i).removeClass('is-active');
                block.eq(i).parent().animate({
                    'max-height': parseInt(block.eq(2).parent().css("height"))
                }, 0);
            }
        }
    });
})